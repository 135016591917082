<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
export default {
	mixins: [productCardMixin],
}
</script>

<template>
	<v-list-item link :to="productRoute" @click="cardClick()">
		<v-list-item-avatar>
			<Media :src="product.images[0].thumb" width="100%" aspect-ratio="1" />
		</v-list-item-avatar>
		<v-list-item-content class="link--text font-3">
			<div v-text="product.name" class="header font-1"></div>
			<PriceLayout
				class="d-flex flex-row-reverse justify-end mt-1"
				:prevPrice="selectedVariant.pvPrice.prevPrice"
				:price="selectedVariant.pvPrice.price"
				:product="product"
				prev-price-class="font-2 grey--text"
				price-class="font-2 mr-2 price"
				discount-text="-"
			/>
		</v-list-item-content>
	</v-list-item>
</template>
<style></style>

