<script>
import scrollMixin from './scrollMixin'

export default {
	mixins: [scrollMixin],
	props: {
		quickSearchEnabled: {
			type: Boolean,
			default: true,
		},
		noIcon: Boolean,
		round: Boolean,
		iconContainerSize: Object,
		iconStyle: [String, Object],
		inputStyle: [String, Object],
	},
	data() {
		return {
			inputText: null,
			quickResults: [],
			searchId: 0,
			quickSearchLoading: false,
			quickSearchShown: false,
			stopSearch: false,
			lastText: null,
			over: false,
			visibilityRef: 'form',
			useSearchScope: false,
			searchConfig: this.$srv('PreFilterConfig'),
		}
	},
	computed: {
		isShowing() {
			return this.quickSearchEnabled && this.quickSearchShown && !this.stopSearch
		},
		listId() {
			return 'quick_search_results'
		},
		iconSize() {
			return this.iconContainerSize || { width: '50px', height: '50px' }
		},
		maxHeight() {
			// if (this.$ssr) return
			return window.innerHeight * 0.75
		},
	},
	methods: {
		focus() {
			this.$refs.input.focus()
		},
		blur() {
			this.$refs.input.blur()
		},
		goToShopWithSearchQuery() {
			this.quickSearchShown = false
			this.$shop.search(this.inputText, this.useSearchScope)
		},
		runQuickSearch() {
			let text = this.inputText
			text = text ? text.trim() : ''
			this.searchId += 1

			if (text.length < 2 || this.stopSearch) {
				this.quickSearchShown = false
				return
			}

			this.quickSearchShown = true

			if (text == this.lastText) {
				this.quickSearchLoading = false
				return
			}
			this.quickSearchLoading = true
			let searchId = this.searchId

			setTimeout(() => {
				if (searchId != this.searchId) return

				this.$shopApi.post({
					url: '/catalog/quick-search',
					// data: {
					// 	filters: [process.env.VUE_APP_SHOP_ALL_KEYWORD],
					// 	search: text,
					// 	page: 1,
					// 	limit: 10,
					// 	dataSize: 'small',
					// },
					data: {
						search: text,
					},
					onSuccess: ({ data }) => {
						if (searchId != this.searchId) return

						const { products } = data
						this.quickResults = products
						this.lastText = text
						this.quickSearchLoading = false

						this.$eventer().trigger('search:search', {
							searchTerm: text,
						})

						if (products.length) {
							this.$eventer().trigger('search:view-results', {
								products,
								listId: this.listId,
								searchTerm: text,
							})
						}
					},
				})
			}, 500)
		},
		onBlur() {
			if (this.over) return
			this.$nextTick(() => {
				setTimeout(() => {
					this.quickSearchShown = false
					// this.inputText = null
					this.$emit('blur')
				}, 100)
			})
		},
		onProductClick() {
			this.quickSearchShown = false
			this.over = false
			this.$emit('product:click')
		},
		compareFn(elementRect) {
			return elementRect.top > -20 && elementRect.bottom <= window.innerHeight
		},
		onNotVisibleCb() {
			this.inputText = null
			this.quickSearchShown = false
		},
	},
	eventsListeners: {
		focusMobileSearch() {
			setTimeout(() => {
				this.focus()
			}, 200)
		},
		'shop-list:loaded'() {
			let querySearch = this.$route.query?.search?.trim()
			if (querySearch) {
				this.inputText = querySearch
				this.stopSearch = true
				this.$nextTick(() => (this.stopSearch = false))
			}
		},
	},
	watch: {
		inputText() {
			this.runQuickSearch()
		},
	},
}
</script>
<template>
	<form
		:ref="visibilityRef"
		class="appbar link d-flex justify-space-between input-container"
		:class="{ round }"
		@submit.prevent="goToShopWithSearchQuery"
	>
		<input
			ref="input"
			autocomplete="off"
			:value="inputText"
			@input="(event) => (inputText = event.target.value)"
			type="search"
			class="pl-3 w100 font-2"
			name="search"
			@focus="runQuickSearch()"
			@blur="onBlur()"
			placeholder="¿Qué estás buscando?"
			:style="inputStyle"
		/>
		<div
			class="quick-search pt-1"
			v-if="quickSearchEnabled && quickSearchShown && !stopSearch"
			@mouseenter="over = true"
			@mouseleave="over = false"
		>
			<div class="quick-search__results elevation-16 rounded overflow-hidden link">
				<v-list dense :style="`max-height: ${maxHeight}px; overflow: auto`">
					<v-subheader style="color: var(--strong)">Resultados para "{{ inputText }}"</v-subheader>
					<div v-if="quickSearchLoading">
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
					</div>
					<div v-else-if="quickResults.length">
						<AutocompleteSearchField-ProductItem
							v-for="(product, listIndex) of quickResults"
							:key="listIndex"
							v-bind="{ product, listId, listIndex }"
							@card:click="onProductClick()"
						/>
						<v-list-item @click="goToShopWithSearchQuery" class="d-flex justify-center align-center">
							<div class="text-decoration-underline" style="color: var(--strong)">
								Ver todos los resultados
							</div>
						</v-list-item>
					</div>
					<div v-else class="text-center" @click="quickSearchShown = false">
						<div>Sin coincidencias</div>
						<router-link :to="$shop.getShopRoute()" class="text-decoration-underline">
							Ver todos los productos
						</router-link>
					</div>
				</v-list>
			</div>
		</div>
		<div v-if="searchConfig" class="d-flex align-content-center justify-end pr-2" style="margin-top: 2px">
			<v-checkbox
				dense
				v-model="useSearchScope"
				:color="$vars.warning"
				:class="$b.d && 'mt-0'"
				hide-details
			>
				<template #label>
					<span :class="$b.m && 'font-1'" class="success">{{ searchConfig.label }}</span>
				</template>
			</v-checkbox>
		</div>
		<div
			v-if="!noIcon"
			class="d-flex align-center justify-center icon-container pointer"
			@click="goToShopWithSearchQuery"
			:style="iconSize"
		>
			<v-icon size="30" class="icon" :style="iconStyle">mdi-magnify</v-icon>
		</div>
	</form>
</template>

<style scoped lang="scss">
::v-deep .v-label {
	white-space: nowrap;
}
.input-container {
	border-radius: 0.5rem;
	position: relative;
	border: 1px solid var(--linktext);
}

.icon-container {
	border-radius: 0 0.5rem 0 1rem;
}

input {
	outline: none;
	color: var(--strongtext);
}

::v-deep input::placeholder {
	color: var(--strongtext);
}

.quick-search {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateY(100%);
	z-index: 100;
}

.icon {
	color: var(--strongtext);
}

.round {
	border-radius: 100vh;
}
</style>

