<script>
export default {
	lang: 'login',
	name: 'SignupForm',
	data() {
		return {
			user: {},
			loading: false,
			validation: {},
		}
	},
	methods: {
		submitSignup() {
			this.loading = true
			this.$shopApi.post({
				url: '/user/signup',
				loading: (v) => (this.loading = v),
				data: {
					firstname: this.user.firstname,
					lastname: this.user.lastname,
					rawPassword: this.user.rawPassword,
					accountEmail: this.user.accountEmail,
				},
				onValidation: ({ validation }) => (this.validation = validation),
				done: ({ data, success }) => {
					if (success) {
						let { loginMethod, user } = data
						this.$store.set('shop/user', user)
						this.$store.set('shop/loginDrawer', false)
						this.$eventer().trigger('user:signup', { loginMethod, user })
					} else {
						this.loginError = true
					}
				},
			})
		},
		openLogin() {
			this.$emit('open-form', 'login')
		},
	},
	mounted() {
		this.$eventer().trigger('signup-form:view')
	},
}
</script>

<template>
	<div>
		<ThirdPartyLoginsButtons />
		<div class="py-4">
			<div class="font-weight-bold pb-4">{{ 'Regístrate con tu e-mail' | lang }}</div>
			<Validator :validation="validation">
				<v-form @submit.prevent="submitSignup">
					<v-row>
						<v-col cols="12" md="6">
							<TextField v-model="user.firstname" :label="$lang('Nombre')" />
						</v-col>
						<v-col cols="12" md="6">
							<TextField v-model="user.lastname" :label="$lang('Apellido')" />
						</v-col>
						<v-col cols="12">
							<TextField v-model="user.accountEmail" :label="$lang('E-mail')" />
						</v-col>
						<v-col cols="12">
							<TextField v-model="user.rawPassword" :label="$lang('Contraseña')" type="password" />
						</v-col>
						<v-col cols="12" class="d-flex align-center flex-wrap">
							<Button color="success" @click="submitSignup" :loading="loading">
								{{ 'Crear cuenta' | lang }}
							</Button>
							<v-spacer />
							<Button color="primary" text small @click="openLogin">
								{{ 'Ya tengo una cuenta' | lang }}
							</Button>
						</v-col>
					</v-row>
				</v-form>
			</Validator>
		</div>
	</div>
</template>

